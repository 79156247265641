

.blob-yellow{
  position: absolute;
  right: 0px;
  pointer-events:none; 
  z-index: 0;
}

.shrink-yellow{
  height: 25%;
  width: 25%;
  opacity: 50%;
}

.blob-blue{
  position: absolute;
  bottom: 0px;
  left: 0px;
  pointer-events:none; 
  z-index: 0;
}

.shrink-blue{
  height: 10%;
  width: 10%;
}

.quiz-main {
  margin: 2rem auto;
  max-width: 550px;
  background-color: #F5F7FB;
  position:relative;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 0 1rem;
}


.title{
  text-align: center;
  margin: auto;
  }

.title > h3 {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 31.25px;
  line-height: 37px;
  color: #293264;
  margin-bottom: 7px;
}

.title > p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #293264;
  margin-bottom: 30px;
}

.start-button{
  width: 193px;
  height: 52px;
  background-color: #4D5B9E;
  color: #F5F7FB;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 2rem;
}

.quiz-wrap{
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 85%;
  z-index: 5;
}

.quiz-box{
  max-width: 400px;
  font-family: 'Inter';
  font-style: normal;
  
  font-size: 10.24px;
  line-height: 12px;
  margin-bottom: 20px;
  color: #293264;
}

.quiz-all{
  margin-top: 40px;
  
}

.quiz-question{
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #293264;
}

.buttons-container{
  display: inline-block;
}

.answer-button{
  height: 20px;
  border-radius: 7px;
  background: #F5F7FB;
  border: solid 0.8px #4D5B9E;
  margin-top: 10px;
  margin-right: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10.24px;
  color: #293264;  
}

.button-selected{
  background-color: #D6DBF5;
  border: none;
}

.button-check-correct{
  background-color: #94D7A2;
  border: none;
}

.button-check-incorrect{
  background-color: #F8BCBC;
  border: none;
}
.button-check-notCorrect{
  opacity: 70%
}

hr{
  margin-top: 15px;
}

.check-container{
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.check-button{
  height: 2rem;
  width: 9rem;
  background-color: #4D5B9E;
  color: #F5F7FB;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10.24px;
  line-height: 12px;
  margin: 0.8rem 0;
}
 
.play-button{
  height: 2rem;
  width: 5rem;
  margin: 0.8rem 0.3rem;
}

.score{
  text-align: center;
}

.score > span{
  font-size: 0.8rem;
}