@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Roboto+Slab:400,700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --ff-primary: 'Lora', serif;
    --ff-secondary: 'Roboto Slab', serif;
    
    --fw-reg: 400;
    --fw-bold: 700;
    
    --color-light: #fff;
    --color-dark: #303030;
    --color-accent: #3F83F8;
    
    --fs-h1: 3rem;
    --fs-h2: 2.25rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    
    --bs: 0.25em 0.25em 0.75em rgba(0,0,0,.25),
          0.125em 0.125em 0.25em rgba(0,0,0,.15);

}

#root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

html {
    scroll-behavior: smooth;
}



body {
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
}

.header{
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;

}

a {
   text-decoration: none;
   color: #fff;
   cursor: pointer;
}

img {
    display: block;
    max-width: 100%;
}

strong { font-weight: var(--fw-bold) }

:focus {
    outline: 3px solid var(--color-accent);
    outline-offset: 3px;
}

section {
    padding: 5em 2em;
}

h1,
h2,
h3 {
    line-height: 1;
    margin: 0;
}

h1 { font-size: var(--fs-h1) }
h2 { font-size: var(--fs-h2) }
h3 { font-size: var(--fs-h3) }

#dev-logo{
  color: rgb(119, 113, 113);
}

nav,
#dev-logo{
  margin: auto 1rem;
}

.nav-button{
  display: flex;
  align-items: center;
  margin:auto 1.7rem;
}


nav{
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(54, 53, 59);
  color: #fff;
  transition: 0.5s;
  overflow-x: hidden
}

.slide{
  width: 100vw;
}

/* slide media */
@media (min-width: 600px) {
  .slide{
    width: 600px;
  }
}

/* slide media */
@media (min-width: 800px) {
  .slide{
    width: 400px;
  }
}

.resume{
  margin-right: 2em;
  color: var(--color-accent);
}

/************
 * hamburger
 ***********/

#hamburger{
  display: block;
  z-index: 11;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 5px;
}

#hamburger input{
  display: block;
  width: 30px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

#hamburger span{
  display: block;
  width: 28px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: black;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#hamburger span:first-child{
  transform-origin: 0% 0%;
}

#hamburger span:nth-last-child(2){
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#hamburger input:checked ~ span{
  opacity: 1;
  transform: rotate(45deg) translate(-7px, -11px);
  background: white;
}

/*
 * hide the middle one.
 */
#hamburger input:checked ~ span:nth-last-child(3){
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Last one go the other direction
 */
#hamburger input:checked ~ span:nth-last-child(2){
  transform: rotate(-45deg) translate(-6px, 11px);
}

/**********
scroll to top button
**********/

.ui-to-top{
    border-radius: 50%;
    position: fixed;
    right: 60px;
    bottom: -10px;
    overflow: hidden;
    text-align: center;
    z-index: 20;
    transition: .3s;
    box-shadow: 0 0 1px 0px rgba(32, 41, 47, 0.3);
    border: 1px solid green;
}

.scroll-active{
    transform:translateY(-70px); 
    
}

.top-button{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 1;
    color: #fff;
    background-color: var(--color-accent);
    transition: .3s;
}

.top-button:hover{
    background-color: #3f9cc8;
}

/********
* Nav
********/

.nav-list {
    list-style: none;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 5rem 0 0 0;
    padding: 0;
}

.nav-link {
    color: inherit;
    font-weight: var(--fw-bold);
    font-size: 4rem;
    text-decoration: none;
    white-space: nowrap;
    position: relative;
}

.nav-link::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

.nav-link:hover::after{
  transform: scaleX(1);
  transform-origin: left;
}

.nav-link:hover {
    color: var(--color-accent);
}

.nav-link:focus {
    color: var(--color-accent);
    outline: none;
    border-bottom: 5px solid var(--color-accent);
}



.intro{
  position: relative;
}

.section-title{
  margin-bottom: 0.25em;
  align-self: center;
}

.section-title-intro{
  font-weight: var(--fw-reg);
}

.section-title-intro strong {
    display: block;
}

.section-subtitle{
  margin: 0;
  font-size: var(--fs-h3);
}

.section-subtitle-intro,
.section-subtitle-about {
    background: var(--color-accent);
    padding: .25em 1em;
    font-family: var(--ff-secondary);
    margin-bottom: 1em;
    color: var(--color-light);
}

.section-subtitle-intro {
    display: inline-block;
    color: var(--color-light);
}



.section-title-services::after {
    content: '';
    display: block;
    width: 2em;
    height: 1px;
    margin: 0.5em auto 1em;
    background: var(--color-light);
    opacity: 0.25;
}

.intro-img,
.about-me-img{
box-shadow: var(--bs);
}

.about-me {
    max-width: 1000px;
    margin: 0 auto;
}

.main-container{
  margin-top: 3rem;  
  width: 100vw;  
}

#services{
  background-image: url(../public/images/services-bg7.jpg);
  background-size:cover;
  color: #fff;
  text-align: center;
  margin-bottom: 4em;
}

.service {
    max-width: 400px;
    margin: 0 auto 2em;
}

.btn{
  background-color: #3F83F8;
  font-size: 1.125em;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1em 2.5em;
  margin: 1.4em 0;
  position: relative;
  z-index: 1;
}

.btn:focus{
  outline: none;
  
}

.btn::after{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transform: scale(0);
  transform-origin: bottom right;
  transition: transform 200ms ease-in;
}

.btn:hover::after{
  transform: scale(1);
  transform-origin: top left;
}



/* media */
@media (min-width: 800px) {
    .services {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .service + .service {
        margin-left: 2em;
    }

    .service {
        margin-bottom: 0;
    }
}

.my-work-intro{
  width: 100%;
  background-color: #303030;
  text-align: center;
  color: #fff;
  padding: 3em 0;
}

.portfolio{
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 3rem;
  margin-bottom: 2rem;
}

.portfolio-item{
  width: 400px;
  height: 400px;
}

.portfolio-title{
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
  color: var(--color-accent);
}

.portfolio-title::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 250ms ease-in;
}

.portfolio-item:hover .portfolio-title::after{
  transform: scaleX(1);
  transform-origin: left;
}

.portfolio-img-container{
  overflow: hidden;
}

.portfolio-img{
  transition: 0.5s all ease-in-out;
  object-fit:cover;
  width: 100%;
  height: 300px;
}

.portfolio-item:hover .portfolio-img{
  transform: scale(1.2);
  opacity: .5;
}

.logo-img{
  max-height: 50px;
  margin: 0 0.5rem;
}


.social-list {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 2em 0 0;
    padding: 0;
}

.social-list-item {
    margin: 0 .5em;
}

.social-list-link {
    padding: .5em;
}


/* Individual portfolio item styles */

.portfolio-intro {
    padding: 5em 2em 2em 2em;
}

.portfolio-item-individual {
    padding: 0 2em 2em;
    max-width: 1000px;
    margin: 0 auto;
}

.portfolio-item-individual p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.portfolio-item-individual div {
    display: flex;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.portfolio-item-individual a {
    margin-right: 0.8em;
    font-size: 1rem;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
    padding: 0.25em 1em;
    text-decoration: none;
}

.btn-container{
  text-align: center;
  padding-bottom: 2em;
}


/*********
* Footer
*********/

.footer {
    background: #111;
    text-align: center;
    padding: 2.5em 0;
    color: #3F83F8;
    margin-top: auto;
}

.footer a { 
    color: inherit;
    text-decoration: none;
}

.footer-link {
    font-weight: bold;
}

.footer-link:hover,
.social-list-link:hover {
    opacity: .7;
}

.footer-link:hover {
    text-decoration: underline;
}




/*  media  */

@media (min-width: 800px) {
   :root {
        --fs-h1: 4.0rem;
        --fs-h2: 3.75rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
    }
    .logo-img{
      max-height: 70px;
  }
  .nav-link {
    font-size: 3rem;
  }
}

@media (min-width: 600px) {

    .nav-link {
      font-size: 3.5rem;
    }
   
    .about-me {
        display: grid;
        grid-template-columns: 1fr 200px;
        grid-template-areas: 
            "title img"
            "subtitle img"
            "text img";
        grid-column-gap: 2em;
    }
    
    .section-title-about {
        grid-area: title;
    }
    
    .section-subtitle-about {
        grid-column: 1 / -1;
        grid-row: 2;
        position: relative;
        left: -1em;
        width: calc(100% + 2em);
        padding-left: 1em;
        padding-right: calc(200px + 4em);
    }
    
    .about-me-img {
        grid-area: img;
        position: relative;
        z-index: 2;
    }

    .about-me-body{
      grid-area: text;
      z-index: 2;
    }

    .intro {
        display: grid;
        width: min-content;
        margin: 0 auto;
        grid-column-gap: 1em;
        grid-template-areas: 
            "img title"
            "img subtitle";
        grid-template-columns: min-content max-content;
    }
    
    .intro-img {
        grid-area: img;
        min-width: 250px;
        position: relative;
        z-index: 2;
    }    
    
    .section-subtitle-intro {
        align-self: start;
        grid-column: -1 / 1;
        grid-row: 2;
        text-align: right;
        position: relative;
        left: -1.5em;
        width: calc(100% + 1.5em);
    }
}


/**********
* not found page
**********/

.not-found-wrapper{
    width: 100%;
    height: 82vh;
    display: flex;
    justify-content: center;
}

.not-found{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found h3{
    font-family: cabin,sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px;
}

.not-found-404{
    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found h1{
    transform: translate(0%,-15%);
    font-family: montserrat,sans-serif;
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
}

.not-found h1 > span {
    text-shadow: -8px 0 0 #fff;
}

.not-found h2{
    transform: translate(0%,-60px);
    font-family: cabin,sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px;
}