.tenzies-main {
  background-color: whitesmoke;
  max-width: 500px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 2rem;
  margin: 2rem auto;
}

.tenzies-title {
    font-size: 40px;
    margin: 0;
}

.instructions {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-top: 0;
    text-align: center;
}

.dice-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-bottom: 30px;
  margin: 2rem auto;
}

.roll {
  border: none;
  border-radius: 6px;
  background-color: #5035FF;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.8rem 3rem;
}

.dice {
  background-color: white;
  width: 65px;
  height: 65px;
  border-radius: 6px;
}

.face{
  height: 100%;
  width: 100%;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.one::before{
  content: '';
  width: 20%;
  height: 20%;
  background: black;
  border-radius: 50%;
}

.two::before {
  content: '';
  width: 20%;
  height: 20%;
  border-radius: 50%;
  box-shadow: black -20px -20px 0px 0px,
    black 20px 20px 0px 0px;
}

.three::before {
  content: '';
  width: 20%;
  height: 20%;
  background: black;
  border-radius: 50%;
  box-shadow: black -20px -20px 0px 0px,
    black 20px 20px 0px 0px;
}

.four::before {
  content: '';
  width: 20%;
  height: 20%;  
  border-radius: 50%;
  box-shadow: black -20px -20px 0px 0px,
    black -20px 20px 0px 0px, black 20px -20px 0px 0px,
    black 20px 20px 0px 0px;
}

.five::before {
  content: '';
  width: 20%;
  height: 20%;
  background: black;
  border-radius: 50%;
  box-shadow:black -20px -20px 0px 0px,
    black -20px 20px 0px 0px, black 20px -20px 0px 0px,
    black 20px 20px 0px 0px;
}

.six::before {
  content: '';
  width: 20%;
  height: 20%;
  border-radius: 50%;
  box-shadow: black -20px -20px 0px 0px, black -20px 0px 0px 0px,
    black -20px 20px 0px 0px, black 20px -20px 0px 0px, black 20px 0px 0px 0px,
    black 20px 20px 0px 0px;
}

.timer{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.stopper-timer{
  padding: 3px;
}

.best--timer{
  background-color: #5035FF;
  color: white;
  border-radius: 5px;
  padding: 3px;
  text-align: center;
}