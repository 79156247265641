* {
    box-sizing: border-box;
}

.trav__nav {
    display: flex;
    justify-content: center;
    background-color: #F55A5A;
    height: 55px;
    align-items: center;
    color: white;
    padding: 20px 0;
}

.trav__nav > h2 {
    padding: 7px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14.4608px;
    line-height: 18px;
    letter-spacing: -0.075em;
}

.trav__main{
    margin: 45px auto;
    max-width: 590px;
    padding: 1em 1.5em;

}


.card__container {
    
    display: flex;
    
}

.card_img_container{
    flex-shrink: 0;
    width: 125px;
    height: 168px;
}

.card__photo {
    object-fit:cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card__info {
    margin-left: 19px;
}

.fa_item {
    color: red;
    padding-right: 4px;
    font-size: 9px;
}

.card__location{
    font-weight: 400;
    font-size: 10.24px;
    line-height: 12px;
    letter-spacing: 0.17em;
    color: #2B283A;
    padding-right: 12px;
}

.card__link {
    font-weight: 400;
    font-size: 10.24px;
    line-height: 12px;
    color: #918E9B;
}

.card__date{
    font-weight: 700;
    font-size: 10.24px;
    line-height: 12px;
    color: #2B283A;
}

.card_description{
    font-weight: 400;
    font-size: 10.24px;
    line-height: 150%;
    color: #2B283A;
    margin-right: 20px
}

.card_hr{
    border: 1px solid #F5F5F5;
    margin: 18px 0;   
}

.trav__main div:last-child hr {
    display: none;
}